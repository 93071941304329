<template>
    <div class="vs-row">
        <vx-card actionable class="cardx" title="Update Agreed Cost">
            <div class="gap">
                <vs-row>
                    <vs-col
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="center"
                        vs-w="10"
                    >
                        <b style="color: gray; margin-right: 2%">{{
                            identityCityCode
                        }}</b>
                        <div
                            style="
                                margin-right: 2%;
                                width: 250px;
                                z-index: 1000;
                            "
                            v-show="
                                getrole('cm4') ||
                                getrole('cm3') ||
                                getrole('fi1') ||
                                getrole('wc') ||
                                getrole('ac2')
                            "
                        >
                            <v-select
                                id="courseselect"
                                class="w-full"
                                placeholder="City"
                                v-model="city"
                                :options="cities"
                                label="text"
                            ></v-select>
                        </div>
                        <vs-input v-model="canid" type="number" />
                        <vs-button
                            radius
                            color="dark"
                            type="gradient"
                            icon="search"
                            @click="openAddReceipt()"
                            style="margin-left: 3%"
                        ></vs-button>
                        <vs-button
                            radius
                            color="dark"
                            type="gradient"
                            icon="refresh"
                            @click="refreshReceipts()"
                            style="margin-left: 3%"
                        ></vs-button>
                    </vs-col>
                    <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-button color="dark" type="gradient" icon="add" @click="addenrollment()"></vs-button>
          </vs-col>-->
                </vs-row>
            </div>
            <div class="gap">
                <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12"> -->
                <vs-table :data="receipts" v-if="visible">
                    <!-- <template slot="header">
            <h3>Enrollments</h3>
          </template>-->
                    <template slot="thead" class="exc">
                        <vs-th>Can-ID</vs-th>
                        <vs-th>Name</vs-th>
                        <!-- <vs-th>Subjects Chosen</vs-th> -->
                        <!-- <vs-th>City</vs-th> -->
                        <!-- <vs-th>Course</vs-th> -->
                        <vs-th>Type</vs-th>
                        <vs-th>Name</vs-th>
                        <!-- <vs-th>Mode</vs-th>
            <vs-th>Mode of Study</vs-th>
            <vs-th>Category</vs-th>-->
                        <vs-th>Agreed Cost</vs-th>
                        <vs-th>Paid</vs-th>
                        <!-- <vs-th>Amount In Process</vs-th> -->
                        <vs-th>Pending</vs-th>
                        <!-- <vs-th>Actual Cost</vs-th> -->
                        <!-- <vs-th>Batch</vs-th> -->
                        <!-- <vs-th>Date of Enrollment</vs-th> -->
                        <vs-th v-if="!getrole('cm1')">Add Receipt</vs-th>
                        <vs-th>Agreed Fees</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <vs-tr :key="item.spoc_name" v-for="item in data">
                             <vs-td :data="item.spoc_name">
                                <div>{{ item.identity }}</div>
                            </vs-td>

                            <vs-td :data="item.call_utilization">
                                <div>{{ item.person_name }}</div>
                            </vs-td>

                            <vs-td :data="item.call_utilization">
                                <div>{{ item.type }}</div>
                            </vs-td>

                            <vs-td :data="item.call_utilization">
                                <div>{{ item.name }}</div>
                            </vs-td>

                            <!-- <vs-td :data="item.visitor_utilization">
                <div>{{item.subjects_chosen}}</div>
              </vs-td>-->

                            <!-- <vs-td :data="item.training_utilization">
                <div>{{item.city}}</div>
              </vs-td>-->

                            <!-- <vs-td :data="item.total_utilization">
                <div>{{ item.course }}</div>
              </vs-td> -->

                            <!-- <vs-td :data="item.total_calls">
                <div>{{sortText(item.mode)}}</div>
              </vs-td>
              <vs-td :data="item.mwb_call_count">
                <div>{{sortText(item.mode_of_study)}}</div>
              </vs-td>
              <vs-td :data="item.ewb_call_count">
                <div>{{item.category}}</div>
              </vs-td>-->

                            <vs-td :data="item.untracked_in_calls_count">
                                <div>{{ item.agreed_cost }}</div>
                            </vs-td>

                            <vs-td :data="item.missed_call_count">
                                <div>{{ sumOfAmount(item.receipts) }}</div>
                            </vs-td>
                            <!-- <vs-td :data="item.missed_call_count">
								<div>{{ item.amount_in_process }}</div>
							</vs-td> -->

                            <vs-td :data="item.missed_call_count">
                                <div>
                                    {{
                                        item.agreed_cost -
                                        sumOfAmount(item.receipts)
                                    }}
                                </div>
                            </vs-td>

                            <!-- <vs-td :data="item.call_30_60">
                <div>{{ item.batch }}</div>
              </vs-td> -->

                            <!-- <vs-td :data="item.nuc_count">
                <div>{{ unixTimestampConvert(item.enrollment_date) }}</div>
              </vs-td> -->
                            <vs-td
                                :data="item.nuc_count"
                                v-if="!getrole('cm1')"
                            >
                                <div>
                                    <vs-row>
                                        <vs-button
                                            color="dark"
                                            type="gradient"
                                            icon="add"
                                            @click="addenrollment(item)"
                                        ></vs-button>
                                        <vs-button
                                            style="margin-left: 5%"
                                            v-if="
                                                item.name === 'Miscellaneous' &&
                                                item.amount_pending === 2500
                                            "
                                            color="danger"
                                            type="gradient"
                                            icon="delete"
                                            @click="Removepurchase(item)"
                                        ></vs-button>
                                    </vs-row>
                                </div>
                            </vs-td>

                            <vs-td :data="item.nuc_count">
                                <div>
                                    <vs-row>
                                        <vs-button
                                            color="dark"
                                            type="gradient"
                                            @click="agreedfeepopup(item)"
                                            size="small"
                                            >Change Agreed Fees</vs-button
                                        >
                                    </vs-row>
                                </div>
                            </vs-td>

                            <template class="expand-user" slot="expand">
                                <vs-row>
                                    <vs-col
                                        vs-type="flex"
                                        vs-justify="flex-start"
                                        vs-align="center"
                                        vs-w="12"
                                    >
                                        <div style="width: 100%">
                                            <vs-table :data="item.receipts">
                                                <template slot="thead">
                                                    <vs-th>Can-ID</vs-th>
                                                    <vs-th>Person Name</vs-th>
                                                    <vs-th
                                                        >Transaction Type</vs-th
                                                    >
                                                    <vs-th>Cheque No</vs-th>
                                                    <vs-th>PDC Date</vs-th>
                                                    <vs-th
                                                        >Transaction Id</vs-th
                                                    >
                                                    <vs-th>TID</vs-th>
                                                    <vs-th>City</vs-th>
                                                    <vs-th>Receipt No</vs-th>
                                                    <vs-th>Amount</vs-th>
                                                    <vs-th>Receipt Date</vs-th>
                                                    <vs-th>Deposit Date</vs-th>
                                                    <vs-th>MR Number</vs-th>
                                                    <vs-th>Action</vs-th>
                                                </template>

                                                <template slot-scope="{ data }">
                                                    <vs-tr
                                                        :key="indextr"
                                                        v-for="(
                                                            tr, indextr
                                                        ) in data"
                                                    >
                                                        <vs-td>{{
                                                            tr.identity
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.person_name
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.transaction_type
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.cheque_no
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.pde_date
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.transaction_id
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.cc_machine_tid_no
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.city
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.receipt_no
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.amount
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            unixTimestampConvert(
                                                                tr.receipt_date
                                                            )
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            unixTimestampConvert(
                                                                tr.deposit_date
                                                            )
                                                        }}</vs-td>
                                                        <vs-td>{{
                                                            tr.mr_number
                                                        }}</vs-td>
                                                        <vs-td>
                                                            <vs-button
                                                                size="small"
                                                                color="rgb(134, 4, 98)"
                                                                type="filled"
                                                                @click="
                                                                    split(tr)
                                                                "
                                                                >Split
                                                                &nbsp;&nbsp;&nbsp;
                                                                Receipt</vs-button
                                                            >
                                                            <vs-button
                                                                style="
                                                                    margin-top: 4%;
                                                                "
                                                                size="small"
                                                                color="rgb(134, 4, 98)"
                                                                type="filled"
                                                                @click="
                                                                    resendReceipt(
                                                                        tr
                                                                    )
                                                                "
                                                                >Resend
                                                                Receipt</vs-button
                                                            >
                                                        </vs-td>
                                                    </vs-tr>
                                                </template>
                                            </vs-table>
                                        </div>
                                    </vs-col>
                                </vs-row>
                            </template>
                        </vs-tr>
                    </template>
                </vs-table>
                <!--  agreed fee popup -->
                <vs-popup
                    classContent="popup-example"
                    title="Change Agreed Fee"
                    :active.sync="agreed_fee_popup"
                >
                    <vs-row class="gap"
                        ><vs-input
                            class="inputx"
                            placeholder="Can-ID"
                            v-model="identity"
                    /></vs-row>
                    <vs-row class="gap">
                        <vs-input
                            class="inputx"
                            placeholder="Agreed Fees"
                            v-model="agreed_fees"
                        />
                    </vs-row>
                    <vs-row class="gap">
                        <vs-input
                            class="inputx"
                            placeholder="Course"
                            v-model="course"
                        />
                    </vs-row>
                    <div style="display: flex; justify-content: center">
                        <vs-button
                            @click="confirmagreedfee()"
                            color="primary"
                            type="filled"
                            >Submit</vs-button
                        >
                    </div>
                    <div v-show="confirm_agreed_fee">
                        <vs-row class="gap">
                            <b>
                                Are you sure you want to change the Agreed Fee?
                            </b>
                        </vs-row>
                        <vs-row class="gap">
                            <b>Changed Agreed Fee - {{ agreed_fees }}</b>
                        </vs-row>

                        <vs-row vs-type="flex" vs-justify="flex-end">
                            <vs-col class="gap" vs-w="2"
                                ><vs-button
                                    @click="closeAgeedCost()"
                                    style="margin-left: -25%"
                                    color="danger"
                                    type="filled"
                                    >Cancel</vs-button
                                ></vs-col
                            >
                            <vs-col class="gap" vs-w="2">
                                <vs-button
                                    @click="changeAgeedCost()"
                                    color="success"
                                    type="filled"
                                    >Ok</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title="
                        splitdata.person_name +
                        '  ,  Date: ' +
                        convertTimestampToDate(splitdata.receipt_date)
                    "
                    :active.sync="splitreceiptpopup"
                >
                    <div style="height: 230px">
                        <vs-card>
                            <div>
                                <vs-row class="gap">
                                    <vs-col vs-w="7"
                                        ><span><b>Transaction Type : </b></span>
                                        <span>{{
                                            splitdata.transaction_type
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="5"
                                        ><span><b>Transaction Id : </b></span>
                                        <span>{{
                                            splitdata.transaction_id
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Amount : </b></span>
                                        <span>{{
                                            splitdata.amount
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span
                                            ><b
                                                >Receipt Added By [Spoc] :
                                            </b></span
                                        >
                                        <span>{{
                                            splitdata.added_by_name
                                        }}</span></vs-col
                                    >
                                </vs-row>
                            </div>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col vs-w="6">
                                <v-select
                                    v-model="purchase_type"
                                    :options="purchase_types"
                                    placeholder="type"
                                ></v-select>
                            </vs-col>
                            <vs-col vs-w="6">
                                <vs-input
                                    v-model="purchase_amount"
                                    placeholder="amount"
                                ></vs-input>
                            </vs-col>
                        </vs-row>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="success"
                                    type="filled"
                                    @click="splitreceipt"
                                    >submit</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <vs-popup
                    class="holamundo"
                    :title="
                        this.misceliniousdata.person_name +
                        '-' +
                        this.misceliniousdata.type
                    "
                    :active.sync="miscelinouspopup"
                >
                    <div style="height: 230px">
                        <vs-card>
                            <div>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Purchase Type : </b></span>
                                        <span>{{
                                            this.misceliniousdata.type
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Can-ID : </b></span>
                                        <span>{{
                                            this.misceliniousdata.identity
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Agreed Cost : </b></span>
                                        <span>{{
                                            this.misceliniousdata.agreed_cost
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Paid : </b></span>
                                        <span>{{
                                            this.misceliniousdata.amount_paid
                                        }}</span></vs-col
                                    >
                                </vs-row>
                                <vs-row class="gap">
                                    <vs-col vs-w="6"
                                        ><span><b>Amount Pending : </b></span>
                                        <span>{{
                                            this.misceliniousdata.amount_pending
                                        }}</span></vs-col
                                    >
                                    <vs-col vs-w="6"
                                        ><span
                                            ><b
                                                >Purchase Added By [Spoc] :
                                            </b></span
                                        >
                                        <span>{{
                                            this.misceliniousdata
                                                .enrollment_added_by_name
                                        }}</span></vs-col
                                    >
                                </vs-row>
                            </div>
                        </vs-card>
                        <vs-row class="gap">
                            <vs-col
                                vs-w="12"
                                style="display: flex; justify-content: center"
                            >
                                <vs-button
                                    color="danger"
                                    type="filled"
                                    @click="RemoveMiscelinious()"
                                    >Delete</vs-button
                                >
                            </vs-col>
                        </vs-row>
                    </div>
                </vs-popup>
                <!-- </vs-col> -->
                <!-- <vs-table :data="receipts">
        <template slot="thead">
          <vs-th>Can-ID</vs-th>
          <vs-th>Person Name</vs-th>
          <vs-th>Transaction Type</vs-th>
          <vs-th>Cheque No</vs-th>
          <vs-th>PDC Date</vs-th>
          <vs-th>Transaction Id</vs-th>
          <vs-th>City</vs-th>
          <vs-th>Batch</vs-th>
          <vs-th>Amount</vs-th>
          <vs-th>Receipt Date</vs-th>
          <vs-th>MR Number</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{tr.identity}}</vs-td>
            <vs-td>{{tr.person_name}}</vs-td>
            <vs-td>{{tr.transaction_type}}</vs-td>
            <vs-td>{{tr.cheque_no}}</vs-td>
            <vs-td>{{tr.pde_date}}</vs-td>
            <vs-td>{{tr.transaction_id}}</vs-td>
            <vs-td>{{tr.city}}</vs-td>
            <vs-td>{{tr.batch}}</vs-td>
            <vs-td>{{tr.amount}}</vs-td>
            <vs-td>{{unixTimestampConvert(tr.receipt_date)}}</vs-td>
            <vs-td>{{tr.mr_number}}</vs-td>
          </vs-tr>
        </template>
        </vs-table>-->
            </div>
            <vs-row style="margin-top: 3%">
                <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="flex-end"
                    vs-w="12"
                >
                    <vs-pagination
                        :total="tablelinks"
                        v-model="currentpage"
                    ></vs-pagination>
                </vs-col>
            </vs-row>
        </vx-card>
    </div>
</template>

<script>
import eventbus from "../components/eventbus";
import axios from "axios";
import constants from "../../constants.json";
import vSelect from "vue-select";
export default {
    mounted() {
        this.getSpoc();
        this.clear();
        eventbus.$on("reload-receipts", () => {
            this.getReceipts();
        });
    },
    components: {
        vSelect,
    },
    data() {
        return {
            identity: "",
            agreed_fees: "",
            course: "",
            select1: 3,
            options1: [
                { text: "IT", value: 0 },
                { text: "Blade Runner", value: 2 },
                { text: "Thor Ragnarok", value: 3 },
            ],
            value1: "",
            value2: "",
            confirm_agreed_fee: false,
            agreed_fee_popup: false,
            splittooltip: "Testing",
            misceliniousdata: "",
            miscelinouspopup: false,
            splitreceiptpopup: false,
            visible: false,
            receipts: [],
            currentpage: 1,
            tablelinks: 1,
            canid: "",
            city: "",
            cities: [],
            registeredSpoc: "",
            identityCityCode: "",
            activeConfirm: false,
            selectedReceiptForSplit: null,
            splitdata: {
                person_name: "",
                transaction_type: "",
                transaction_id: "",
                amount: "",
                receipt_date: "",
                added_by_name: "",
            },
            purchase_type: "",
            purchase_types: ["Miscellaneous", "Additional Purchase"],
            purchase_amount: null,
            agreecaostData: "",
            oldAgreeCost: ""
        };
    },
    watch: {
        currentpage() {
            this.getReceipts();
        },
        city: function (val) {
            this.identityCityCode = val.substring(0, 1).toUpperCase();
        },
    },
    methods: {
        sumOfAmount(data) {
            // console.log("amount", data)
            var msgTotal = data.reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
            // console.log('Total Messages:', msgTotal);
            return msgTotal;
        },
        clear() {
            this.identity = "";
            this.agreed_fees = "";
            this.course = "";
            this.oldAgreeCost = "";
        },
        confirmagreedfee() {
            this.confirm_agreed_fee = true;
        },
        closeAgeedCost() {
            this.confirm_agreed_fee = false;
            this.agreed_fees = "";
        },
        agreedfeepopup(tr) {
            this.confirm_agreed_fee = false;
            console.log(tr)
            this.clear();
            this.agreed_fee_popup = true;
            this.agreecaostData = tr;
            this.identity = this.agreecaostData.identity;
            this.course = this.agreecaostData.name;
            this.oldAgreeCost = this.agreecaostData.agreed_cost;
        },
        split(tr) {
            this.splitdata.id = tr.id;
            this.splitdata.person_name = tr.person_name;
            this.splitdata.transaction_type = tr.transaction_type;
            this.splitdata.transaction_id = tr.transaction_id;
            this.splitdata.amount = tr.amount;
            this.splitdata.receipt_date = tr.receipt_date;
            this.splitdata.added_by_name = tr.added_by_name;
            this.splitreceiptpopup = true;
        },
        changeAgeedCost() {
            this.$vs.loading();
            let obj = {
                identity : this.agreecaostData.identity,
                course : this.agreecaostData.name,
                new_agreed_cost : this.agreed_fees,
                old_agreed_cost: this.oldAgreeCost
            };
            console.log("obj", obj);
            axios
                .post(
                    `${constants.SERVER_API}changeAgreedFees`,
                    obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status === "success") {
                        this.agreed_fee_popup = false;
                        this.confirm_agreed_fee = false;
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.getReceipts();
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        resendReceipt(tr) {
            let obj = {
                receipt_id: tr.id,
            };
            console.log("obj", obj);
            axios
                .post(
                    `https://crm.milesforce.com/api/resendReceiptEmail`,
                    obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        splitreceipt() {
            let obj = {
                receipt_id: this.splitdata.id,
                amount: this.purchase_amount,
                purchase_type: this.purchase_type,
            };
            console.log("obj", obj);
            axios
                .post(`https://crm.milesforce.com/api/splitReceipt`, obj, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.purchase_type = "";
                        this.purchase_amount = "";
                        this.splitreceiptpopup = false;
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        refreshReceipts() {
            this.canid = "";
            this.city = "";
            this.getReceipts();
        },
        Removepurchase(data) {
            console.log("item", data);
            this.misceliniousdata = data;
            this.miscelinouspopup = true;
        },
        RemoveMiscelinious() {
            let obj = {
                purchase_id: this.misceliniousdata.id,
            };
            console.log("obj", obj);
            axios
                .post(
                    `https://crm.milesforce.com/api/deleteMiscPurchase`,
                    obj,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.userAccessToken}`,
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    if (response.data.status === "success") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "success",
                        });
                        this.miscelinouspopup = false;
                        this.getReceipts();
                    } else if (response.data.status === "warning") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "warning",
                        });
                    } else if (response.data.status === "error") {
                        this.$vs.notify({
                            title: response.data.message,
                            text: "",
                            color: "danger",
                        });
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        getrole(providedrole) {
            if (localStorage.getItem("role") === providedrole) {
                return true;
            } else {
                return false;
            }
        },
        addenrollment(item) {
            //   console.log("emitted");
            eventbus.$emit("open-AddEnrollment", item);
        },
        openAddReceipt() {
            // alert(this.canid);
            this.getReceipts(this.canid);
            /* if (localStorage.getItem("role") === "cm4") {
        eventbus.$emit(
          "open-add-receipt-popup",
          `${this.city.charAt(0)}-${this.canid}`
        );
      } else {
        eventbus.$emit(
          "open-add-receipt-popup",
          `${this.identityCityCode}-${this.canid}`
        );
      } */
        },
        getSpoc() {
            let url = null;
            if (this.getrole("exed2")) {
                url = `https://crm.milesforce.com/api/getExed`;
            } else {
                url = `https://crm.milesforce.com/api/getSpocs`;
            }
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    // console.log(response);
                    this.registeredSpoc = response.data.logged_in_user_id;
                    response.data.spocs.forEach((spoc) => {
                        if (spoc.id === this.registeredSpoc) {
                            // console.log(spoc, "found the spoc");
                            this.identityCityCode = spoc.city.charAt(0);
                            // console.log(this.identityCityCode);
                        }
                    });
                    this.extractSpocByCity(response.data.spocs);
                    this.getReceipts();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        extractSpocByCity(response) {
            // console.log(response);
            var unique = response
                .map((name) => {
                    return {
                        count: 1,
                        name: name.city,
                    };
                })
                .reduce((a, b) => {
                    a[b.name] = (a[b.name] || 0) + b.count;
                    return a;
                }, {});
            let unoderedCities = [];
            let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
            for (var key in unique) {
                if (unique.hasOwnProperty(key)) {
                    if(city_options.includes(key)){
                        unoderedCities.push(key);
                    }
                }
            }
            this.cities = this.sortArrayAlphabetically(unoderedCities);
            // this.city = this.cities[0];
            // this.getEnrollmentstoApprove();
        },
        getReceipts(canid = null) {
            this.$vs.loading();
            this.visible = false;
            let idstring = `page=${this.currentpage}`;
            if (canid !== null) {
                idstring = `identity=${this.identityCityCode}-${canid}`;
            }
            // let url = `${constants.SERVER_API}getAllPurchasesForReceipts?${idstring}`;
            let url = `https://crm.milesforce.com/api/getAllPurchasesForReceipts?${idstring}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${localStorage.userAccessToken}`,
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.$vs.loading.close();
                    if (response.data.status !== "success") {
                        this.handleNotification(response);
                    } else {
                        this.tablelinks = response.data.data.last_page;
                        this.receipts = response.data.data.data;
                        console.log("receipts", this.receipts);
                        this.visible = true;
                    }
                    //   this.amount_paid = response.data.amount_paid;
                    //   this.pending_amount = response.data.amount_pending;
                    //   this.tablelinks = response.data.last_page;
                    //   this.visible = true;
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.handleError(error);
                });
        },
    },
};
</script>

<style>
[data-tooltip],
.tooltip {
    position: relative;
    cursor: pointer;
}
/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
}
/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}
/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
    z-index: 1000;
    padding: 8px;
    width: 160px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
}
/* Directions */
/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
    bottom: 100%;
    left: 50%;
}
[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
    margin-left: -6px;
    margin-bottom: -12px;
    border-top-color: #000;
    border-top-color: hsla(0, 0%, 20%, 0.9);
}
/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
    margin-left: -80px;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
    -webkit-transform: translateY(-12px);
    -moz-transform: translateY(-12px);
    transform: translateY(-12px);
}
/* Left */
.tooltip-left:before,
.tooltip-left:after {
    right: 100%;
    bottom: 50%;
    left: auto;
}
.tooltip-right:before,
.tooltip-right:after {
    bottom: 50%;
    left: 100%;
}
.tooltip-right:before {
    margin-bottom: 0;
    margin-left: -12px;
    border-top-color: transparent;
    border-right-color: #000;
    border-right-color: hsla(0, 0%, 20%, 0.9);
}
.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
    -webkit-transform: translateX(12px);
    -moz-transform: translateX(12px);
    transform: translateX(12px);
}
</style>